
import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    data() {
        return {
            item: {
                name: '',
                code: '',
                platform: undefined as any,
                platform_id: null,
            },
        };
    },
    methods: {
        createItem(): void {
            this.post('products', {
                ...this.item,
                platform_id: this.item.platform?.id,
            })
                .then(() => {
                    this.$router.push({ name: 'products.list' });
                    this.$emit('reload');
                });
        },

    },
});
